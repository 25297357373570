// フッターコンポーネント用スクリプト
export function setupFooter() {
  // DOMが読み込まれた後に実行
  if (typeof document !== "undefined") {
    (() => {
      const accordionButtons = document.querySelectorAll(
        ".profitzCommonFooter__sectionGridItemContent h4 button"
      );

      const ulElements = document.querySelectorAll(
        ".profitzCommonFooter__sectionGridItemContent ul"
      );
      for (const ul of ulElements) {
        if (ul.previousElementSibling?.querySelector("button")) {
          ul.style.maxHeight = "0";
          ul.style.overflow = "hidden";
          ul.style.transition = "max-height 0.3s ease-in-out";
          ul.style.opacity = "0";
          ul.style.transition =
            "max-height 0.3s ease-in-out, opacity 0.3s ease-in-out";
        }
      }

      for (const button of accordionButtons) {
        button.addEventListener("click", function () {
          const ul = this.closest("h4").nextElementSibling;

          if (ul && ul.tagName === "UL") {
            if (ul.style.maxHeight === "0px" || ul.style.maxHeight === "") {
              ul.style.maxHeight = `${ul.scrollHeight}px`;
              ul.style.opacity = "1";
              this.classList.add("minus");
              this.classList.remove("plus");
              this.setAttribute("aria-expanded", "true");
            } else {
              ul.style.maxHeight = "0";
              ul.style.opacity = "0";
              this.classList.add("plus");
              this.classList.remove("minus");
              this.setAttribute("aria-expanded", "false");
            }
          }
        });

        button.setAttribute("aria-expanded", "false");
      }
    })();
  }
}
