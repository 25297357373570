import React, { useEffect } from "react";
import { setupFooter } from "./script";

import "./footer.css";

const Footer = () => {
  useEffect(() => {
    setupFooter();
  }, []);

  return (
    <>
      <footer class="profitzCommonFooter">
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Noto+Sans+JP:wght@100..900&display=swap"
          rel="stylesheet"
        />
        <div class="profitzCommonFooter__inner">
          <div class="profitzCommonFooter__logoBlock">
            <h1 class="profitzCommonFooter__logo">
              <a
                href="https://profitz.jp/"
                target="_blank"
                rel="noopener"
                data-link-type="external0"
              >
                <svg
                  width="150"
                  height="60"
                  viewBox="0 0 150 60"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_47_1391)">
                    <path
                      d="M5.04627 34.782C7.73901 34.782 9.0071 35.0923 10.2021 35.9925C10.8576 36.5493 11.3809 37.2429 11.7345 38.0235C12.0882 38.8041 12.2634 39.6526 12.2477 40.5082C12.2477 42.3031 11.4441 44.129 10.2804 45.024C9.0071 45.9964 7.71292 46.3068 5.22892 46.3068H2.15002V53.7761H0V34.782H5.04627ZM2.15002 44.4342H5.21848C6.82577 44.4342 7.82772 44.2531 8.76705 43.6377C9.21967 43.2536 9.58257 42.7765 9.83059 42.2395C10.0786 41.7026 10.2058 41.1186 10.2033 40.528C10.2008 39.9374 10.0687 39.3545 9.81621 38.8197C9.56369 38.2847 9.19679 37.8107 8.74095 37.4304C7.78075 36.8148 6.79969 36.6339 5.1663 36.6339H2.15002V44.3928V44.4342Z"
                      fill="white"
                    />
                    <path
                      d="M36.0068 44.3308C35.9799 42.3663 36.5429 40.4384 37.6242 38.7919C38.7056 37.1454 40.2565 35.8549 42.0797 35.0843C43.9029 34.3138 45.9161 34.098 47.8634 34.4644C49.8106 34.831 51.6039 35.7631 53.0153 37.1424C54.4267 38.5218 55.3924 40.2859 55.7898 42.2106C56.1871 44.1354 55.998 46.1338 55.2466 47.9517C54.4952 49.7696 53.2154 51.3251 51.5699 52.4203C49.9242 53.5153 47.9874 54.1008 46.0054 54.1019C44.697 54.1282 43.3965 53.8944 42.1807 53.4145C40.9649 52.9344 39.8583 52.218 38.9264 51.3072C37.9944 50.3965 37.2559 49.31 36.7545 48.1118C36.2531 46.9136 35.9988 45.628 36.0068 44.3308ZM38.1567 44.2791C38.1602 46.3851 39.0071 48.4037 40.5114 49.8911C42.0157 51.3785 44.0542 52.213 46.1789 52.2113C48.3037 52.2096 50.3408 51.3718 51.8425 49.882C53.3444 48.3921 54.1879 46.3722 54.1879 44.2662C54.1879 42.1601 53.3444 40.1401 51.8425 38.6502C50.3408 37.1604 48.3037 36.3226 46.1789 36.321C44.0542 36.3192 42.0157 37.1537 40.5114 38.6411C39.0071 40.1286 38.1602 42.1471 38.1567 44.2531V44.2791Z"
                      fill="white"
                    />
                    <path
                      d="M95.1074 34.3785H105.049V36.2925H97.2783V43.1048H104.996V45.0446H97.2783V53.7761H95.1074V34.3785Z"
                      fill="white"
                    />
                    <path
                      d="M112.197 34.3785H114.368V53.7761H112.197V34.3785Z"
                      fill="white"
                    />
                    <path
                      d="M125.593 36.2925H120.781V34.3785H132.628V36.2925H127.79V53.7761H125.593V36.2925Z"
                      fill="white"
                    />
                    <path
                      d="M140.189 51.8621H150V53.7761H137.543V52.3846L147.162 36.2925H137.299V34.3785H149.645V36.0287L140.189 51.8621Z"
                      fill="white"
                    />
                    <path
                      d="M63.2383 17.2467L72.7307 8.96527V46.4464L67.9872 44.2015L63.2383 17.2467Z"
                      fill="white"
                    />
                    <path
                      d="M78.3926 6.99965L86.0429 0.823486V24.1264L78.3926 21.7417V6.99965Z"
                      fill="#384072"
                    />
                    <path
                      d="M69.3125 27.7058L76.0862 22.3004L76.1487 59.647L72.8506 57.6814L69.3125 27.7058Z"
                      fill="#7E85A1"
                    />
                    <path
                      d="M28.0971 44.5893C28.871 43.5023 29.2666 42.1949 29.2243 40.8651C29.2243 38.708 28.1806 36.6855 26.6151 35.7597C25.634 35.1441 24.2354 34.782 21.2296 34.782H18.6621V36.6597H21.3966C23.1291 36.6597 24.3241 36.7321 25.2792 37.3477C25.868 37.7649 26.3471 38.3163 26.6758 38.9554C27.0045 39.5947 27.1735 40.3028 27.1683 41.0203C27.1866 41.67 27.0674 42.3163 26.8184 42.9177C26.5694 43.5191 26.1963 44.0621 25.7227 44.5118C24.6791 45.4584 22.8213 45.4843 21.4748 45.4843H20.8747L26.2863 53.7605H28.6138L23.8285 46.6636C25.5713 46.4877 26.9595 46.1257 28.0971 44.5893Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_47_1391">
                      <rect
                        width="150"
                        height="58.8235"
                        fill="white"
                        transform="translate(0 0.823486)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </a>
            </h1>
            <div class="visible-on-desktop">
              <div class="profitzCommonFooter__mainNavigation">
                <ul>
                  <li>
                    <a
                      href="https://profitz.jp/about"
                      target="_blank"
                      rel="noopener"
                      data-link-type="external0"
                    >
                      プロフィッツについて
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://profitz.jp/services"
                      target="_blank"
                      rel="noopener"
                      data-link-type="external0"
                    >
                      事業紹介
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://profitz.jp/works"
                      target="_blank"
                      rel="noopener"
                      data-link-type="external0"
                    >
                      事例
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://profitz.jp/news"
                      target="_blank"
                      rel="noopener"
                      data-link-type="external0"
                    >
                      お知らせ
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://profitz.jp/columns"
                      target="_blank"
                      rel="noopener"
                      data-link-type="external0"
                    >
                      コラム
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://profitz.jp/company"
                      target="_blank"
                      rel="noopener"
                      data-link-type="external0"
                    >
                      会社概要
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://profitz.jp/recruit"
                      target="_blank"
                      rel="noopener"
                      data-link-type="external0"
                    >
                      採用情報
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://profitz.jp/download"
                      target="_blank"
                      rel="noopener"
                      data-link-type="external0"
                    >
                      ダウンロード
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="visible-on-desktop">
              <div class="profitzCommonFooter__contact">
                <a
                  href="https://profitz.jp/contact"
                  target="_blank"
                  rel="noopener"
                  data-link-type="external0"
                >
                  お問い合わせ
                  <svg
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_105_649)">
                      <path
                        d="M12 4.64703L10.59 6.05703L16.17 11.647H4V13.647H16.17L10.59 19.237L12 20.647L20 12.647L12 4.64703Z"
                        fill="#C8AE52"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_105_649">
                        <rect
                          width="24"
                          height="24"
                          fill="white"
                          transform="translate(0 0.647034)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </a>
              </div>
            </div>
          </div>
          <div class="profitzCommonFooter__navigationBlock visible-on-mobile">
            <div class="profitzCommonFooter__mainNavigation">
              <ul>
                <li>
                  <a
                    href="https://profitz.jp/about"
                    target="_blank"
                    rel="noopener"
                    data-link-type="external0"
                  >
                    プロフィッツについて
                  </a>
                </li>
                <li>
                  <a
                    href="https://profitz.jp/services"
                    target="_blank"
                    rel="noopener"
                    data-link-type="external0"
                  >
                    事業紹介
                  </a>
                </li>
                <li>
                  <a
                    href="https://profitz.jp/works"
                    target="_blank"
                    rel="noopener"
                    data-link-type="external0"
                  >
                    事例
                  </a>
                </li>
                <li>
                  <a
                    href="https://profitz.jp/news"
                    target="_blank"
                    rel="noopener"
                    data-link-type="external0"
                  >
                    お知らせ
                  </a>
                </li>
                <li>
                  <a
                    href="https://profitz.jp/columns"
                    target="_blank"
                    rel="noopener"
                    data-link-type="external0"
                  >
                    コラム
                  </a>
                </li>
                <li>
                  <a
                    href="https://profitz.jp/company"
                    target="_blank"
                    rel="noopener"
                    data-link-type="external0"
                  >
                    会社概要
                  </a>
                </li>
                <li>
                  <a
                    href="https://profitz.jp/recruit"
                    target="_blank"
                    rel="noopener"
                    data-link-type="external0"
                  >
                    採用情報
                  </a>
                </li>
                <li>
                  <a
                    href="https://profitz.jp/download"
                    target="_blank"
                    rel="noopener"
                    data-link-type="external0"
                  >
                    ダウンロード
                  </a>
                </li>
              </ul>
            </div>
            <div class="profitzCommonFooter__subNavigation">
              <ul>
                <li>
                  <a
                    href="https://profitz.jp/p/solicitation-policy"
                    target="_blank"
                    rel="noopener"
                    data-link-type="external0"
                  >
                    金融商品販売における勧誘方針
                  </a>
                </li>
                <li>
                  <a
                    href="https://profitz.jp/p/privacy-policy"
                    target="_blank"
                    rel="noopener"
                    data-link-type="external0"
                  >
                    個人情報保護宣言
                  </a>
                </li>
                <li>
                  <a
                    href="https://profitz.jp/p/adr-policy"
                    target="_blank"
                    rel="noopener"
                    data-link-type="external0"
                  >
                    苦情解決および金融ADR制度における紛争解決の措置方針
                  </a>
                </li>
                <li>
                  <a
                    href="https://profitz.jp/p/antisocial-policy"
                    target="_blank"
                    rel="noopener"
                    data-link-type="external0"
                  >
                    反社会的勢力による被害を防止するための基本方針
                  </a>
                </li>
                <li>
                  <a
                    href="https://profitz.jp/p/disclaimer"
                    target="_blank"
                    rel="noopener"
                    data-link-type="external0"
                  >
                    免責事項と金融商品取引法に基づく表示
                  </a>
                </li>
              </ul>
            </div>
            <div class="profitzCommonFooter__contact">
              <a
                href="https://profitz.jp/contact"
                target="_blank"
                rel="noopener"
                data-link-type="external0"
              >
                お問い合わせ
                <svg
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_105_649)">
                    <path
                      d="M12 4.64703L10.59 6.05703L16.17 11.647H4V13.647H16.17L10.59 19.237L12 20.647L20 12.647L12 4.64703Z"
                      fill="#C8AE52"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_105_649">
                      <rect
                        width="24"
                        height="24"
                        fill="white"
                        transform="translate(0 0.647034)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </a>
            </div>
          </div>
          <div class="profitzCommonFooter__sections">
            <div class="profitzCommonFooter__section">
              <h2 class="profitzCommonFooter__sectionTitle">
                Our Brands / Services Portfolio
              </h2>
              <div class="profitzCommonFooter__sectionGrid">
                <div class="profitzCommonFooter__sectionGridItem">
                  <h3 class="profitzCommonFooter__sectionGridItemTitle">
                    Office
                  </h3>
                  <div class="profitzCommonFooter__sectionGridItemContent">
                    <h4>
                      <span>
                        <a
                          href="https://www.blocks-office.jp/"
                          target="_blank"
                          rel="noopener"
                          data-link-type="external1"
                        >
                          <svg
                            width="217"
                            height="19"
                            viewBox="0 0 217 19"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M0 18.3513V1.28308H5.73096C9.09559 1.28308 11.2179 2.79171 11.2179 6.01603C11.2179 8.28637 10.0717 9.45482 8.80719 9.55095C10.0717 9.64709 11.5359 10.9413 11.5359 13.3521C11.5359 15.9848 9.90162 18.3513 6.12288 18.3513H0ZM3.02447 8.2346H5.75314C7.12118 8.2346 8.06771 7.38415 8.06771 6.13436C8.06771 4.88456 7.06942 4.25597 5.90104 4.25597H3.02447V8.2346ZM3.02447 15.3784H5.90104C7.21731 15.3784 8.41527 14.6685 8.41527 13.3077C8.41527 12.0431 7.41697 11.2593 5.90104 11.2593H3.02447V15.3784Z"
                              fill="white"
                            />
                            <path
                              d="M18.3613 18.3513V1.28308H21.3858V15.3266H28.189V18.3513H18.3613Z"
                              fill="white"
                            />
                            <path
                              d="M33.668 11.9396V7.82043C33.668 4.38165 35.6646 0.994629 39.9092 0.994629C44.1538 0.994629 46.1504 4.38165 46.1504 7.82043V11.9396C46.1504 15.3784 44.1538 18.6471 39.9092 18.6471C35.6646 18.6471 33.668 15.3784 33.668 11.9396ZM43.0815 11.5033V8.19019C43.0815 6.48189 42.5417 4.11542 39.9092 4.11542C37.2766 4.11542 36.7368 6.48189 36.7368 8.19019V11.5033C36.7368 13.4556 37.4171 15.5263 39.9092 15.5263C42.4012 15.5263 43.0815 13.4556 43.0815 11.5033Z"
                              fill="white"
                            />
                            <path
                              d="M52.2441 11.6955V8.01272C52.2441 4.13761 54.4108 0.987244 58.441 0.987244C61.5098 0.987244 63.854 2.88782 64.342 5.81633L61.2732 6.3266C60.8812 4.86235 59.7351 4.20417 58.4484 4.20417C56.2521 4.20417 55.3278 6.00861 55.3278 8.15323V10.9856C55.3278 13.5739 55.912 15.4745 58.2783 15.4745C59.9569 15.4745 61.0809 14.4983 61.251 13.0341L64.3938 13.2559C64.098 16.4285 61.5911 18.6471 58.2265 18.6471C54.5439 18.6471 52.2515 15.8886 52.2515 11.6955H52.2441Z"
                              fill="white"
                            />
                            <path
                              d="M80.4769 18.3513L75.9439 11.0152L74.0434 13.5518V18.3587H70.9746V1.28308H74.0434V8.57478L79.6043 1.28308H83.2869L77.9923 8.30855L84.2335 18.3587H80.4769V18.3513Z"
                              fill="white"
                            />
                            <path
                              d="M88.2574 13.4038L91.4002 13.0119C91.6664 14.7646 92.9087 15.5706 94.2546 15.6446C95.3786 15.7186 96.791 15.2527 96.9611 14.0842C97.0572 13.4482 96.8353 12.6421 94.8388 11.7917L92.5242 10.8377C89.9138 9.69143 88.6937 8.18281 88.6937 5.91247C88.6937 2.98396 90.9861 0.987244 94.2989 0.987244C97.4417 0.987244 99.4679 2.91 99.9338 5.74238L96.9315 6.1787C96.7392 4.9363 95.7853 3.96013 94.2989 3.96013C93.0344 3.96013 91.8587 4.67007 91.8587 5.98642C91.8587 6.98478 92.5168 7.52463 94.3729 8.27894L96.9315 9.37344C99.2017 10.2978 100.178 11.8361 100.178 13.7144C100.178 16.6651 97.7375 18.6397 94.4468 18.6397C91.1562 18.6397 88.6419 16.4951 88.25 13.3964L88.2574 13.4038Z"
                              fill="white"
                            />
                            <path
                              d="M114.627 11.9396V7.82043C114.627 4.38165 116.624 0.994629 120.868 0.994629C125.113 0.994629 127.109 4.38165 127.109 7.82043V11.9396C127.109 15.3784 125.113 18.6471 120.868 18.6471C116.624 18.6471 114.627 15.3784 114.627 11.9396ZM124.041 11.5033V8.19019C124.041 6.48189 123.501 4.11542 120.868 4.11542C118.236 4.11542 117.696 6.48189 117.696 8.19019V11.5033C117.696 13.4556 118.376 15.5263 120.868 15.5263C123.36 15.5263 124.041 13.4556 124.041 11.5033Z"
                              fill="white"
                            />
                            <path
                              d="M133.941 18.3513V1.28308H143.813V4.40387H137.01V8.16065H142.423V11.2814H137.01V18.3513H133.941Z"
                              fill="white"
                            />
                            <path
                              d="M150.41 18.3513V1.28308H160.29V4.40387H153.486V8.16065H158.899V11.2814H153.486V18.3513H150.418H150.41Z"
                              fill="white"
                            />
                            <path
                              d="M166.871 18.3513V1.28308H169.896V18.3513H166.871Z"
                              fill="white"
                            />
                            <path
                              d="M176.721 11.6955V8.01272C176.721 4.13761 178.887 0.987244 182.918 0.987244C185.986 0.987244 188.331 2.88782 188.819 5.81633L185.75 6.3266C185.358 4.86235 184.212 4.20417 182.918 4.20417C180.721 4.20417 179.797 6.00861 179.797 8.15323V10.9856C179.797 13.5739 180.381 15.4745 182.747 15.4745C184.433 15.4745 185.55 14.4983 185.72 13.0341L188.863 13.2559C188.567 16.4285 186.06 18.6471 182.696 18.6471C179.013 18.6471 176.721 15.8886 176.721 11.6955Z"
                              fill="white"
                            />
                            <path
                              d="M195.451 18.3513V1.28308H205.715V4.30773H198.476V8.21242H203.837V11.2371H198.476V15.334H206.063V18.3587H195.459L195.451 18.3513Z"
                              fill="white"
                            />
                            <path
                              d="M0 18.3513V1.28308H5.73096C9.09559 1.28308 11.2179 2.79171 11.2179 6.01603C11.2179 8.28637 10.0717 9.45482 8.80719 9.55095C10.0717 9.64709 11.5359 10.9413 11.5359 13.3521C11.5359 15.9848 9.90162 18.3513 6.12288 18.3513H0ZM3.02447 8.2346H5.75314C7.12118 8.2346 8.06771 7.38415 8.06771 6.13436C8.06771 4.88456 7.06942 4.25597 5.90104 4.25597H3.02447V8.2346ZM3.02447 15.3784H5.90104C7.21731 15.3784 8.41527 14.6685 8.41527 13.3077C8.41527 12.0431 7.41697 11.2593 5.90104 11.2593H3.02447V15.3784Z"
                              fill="white"
                            />
                            <path
                              d="M18.3613 18.3513V1.28308H21.3858V15.3266H28.189V18.3513H18.3613Z"
                              fill="white"
                            />
                            <path
                              d="M33.668 11.9396V7.82043C33.668 4.38165 35.6646 0.994629 39.9092 0.994629C44.1538 0.994629 46.1504 4.38165 46.1504 7.82043V11.9396C46.1504 15.3784 44.1538 18.6471 39.9092 18.6471C35.6646 18.6471 33.668 15.3784 33.668 11.9396ZM43.0815 11.4959V8.1828C43.0815 6.4745 42.5417 4.10802 39.9092 4.10802C37.2766 4.10802 36.7368 6.4745 36.7368 8.1828V11.4959C36.7368 13.4482 37.4171 15.5189 39.9092 15.5189C42.4012 15.5189 43.0815 13.4482 43.0815 11.4959Z"
                              fill="white"
                            />
                            <path
                              d="M52.2441 11.6955V8.01272C52.2441 4.13761 54.4108 0.987244 58.441 0.987244C61.5098 0.987244 63.854 2.88782 64.342 5.81633L61.2732 6.3266C60.8812 4.86235 59.7351 4.20417 58.4484 4.20417C56.2521 4.20417 55.3278 6.00861 55.3278 8.15323V10.9856C55.3278 13.5739 55.912 15.4745 58.2783 15.4745C59.9569 15.4745 61.0809 14.4983 61.251 13.0341L64.3938 13.2559C64.098 16.4285 61.5911 18.6471 58.2265 18.6471C54.5439 18.6471 52.2515 15.8886 52.2515 11.6955H52.2441Z"
                              fill="white"
                            />
                            <path
                              d="M80.4769 18.3513L75.9439 11.0152L74.0434 13.5518V18.3587H70.9746V1.28308H74.0434V8.57478L79.6043 1.28308H83.2869L77.9923 8.30855L84.2335 18.3587H80.4769V18.3513Z"
                              fill="white"
                            />
                            <path
                              d="M88.2574 13.4038L91.4002 13.0119C91.6664 14.7646 92.9087 15.5706 94.2546 15.6446C95.3786 15.7186 96.791 15.2527 96.9611 14.0842C97.0572 13.4482 96.8353 12.6421 94.8388 11.7917L92.5242 10.8377C89.9138 9.69143 88.6937 8.18281 88.6937 5.91247C88.6937 2.98396 90.9861 0.987244 94.2989 0.987244C97.4417 0.987244 99.4679 2.91 99.9338 5.74238L96.9315 6.1787C96.7392 4.9363 95.7853 3.96013 94.2989 3.96013C93.0344 3.96013 91.8587 4.67007 91.8587 5.98642C91.8587 6.98478 92.5168 7.52463 94.3729 8.27894L96.9315 9.37344C99.2017 10.2978 100.178 11.8361 100.178 13.7144C100.178 16.6651 97.7375 18.6397 94.4468 18.6397C91.1562 18.6397 88.6419 16.4951 88.25 13.3964L88.2574 13.4038Z"
                              fill="white"
                            />
                            <path
                              d="M114.627 11.9396V7.82043C114.627 4.38165 116.624 0.994629 120.868 0.994629C125.113 0.994629 127.109 4.38165 127.109 7.82043V11.9396C127.109 15.3784 125.113 18.6471 120.868 18.6471C116.624 18.6471 114.627 15.3784 114.627 11.9396ZM124.041 11.5033V8.19019C124.041 6.48189 123.501 4.11542 120.868 4.11542C118.236 4.11542 117.696 6.48189 117.696 8.19019V11.5033C117.696 13.4556 118.376 15.5263 120.868 15.5263C123.36 15.5263 124.041 13.4556 124.041 11.5033Z"
                              fill="white"
                            />
                            <path
                              d="M133.941 18.3513V1.28308H143.813V4.40387H137.01V8.16065H142.423V11.2814H137.01V18.3513H133.941Z"
                              fill="white"
                            />
                            <path
                              d="M150.41 18.3513V1.28308H160.29V4.40387H153.486V8.16065H158.899V11.2814H153.486V18.3513H150.418H150.41Z"
                              fill="white"
                            />
                            <path
                              d="M166.871 18.3513V1.28308H169.896V18.3513H166.871Z"
                              fill="white"
                            />
                            <path
                              d="M176.721 11.6955V8.01272C176.721 4.13761 178.887 0.987244 182.918 0.987244C185.986 0.987244 188.331 2.88782 188.819 5.81633L185.75 6.3266C185.358 4.86235 184.212 4.20417 182.918 4.20417C180.721 4.20417 179.797 6.00861 179.797 8.15323V10.9856C179.797 13.5739 180.381 15.4745 182.747 15.4745C184.433 15.4745 185.55 14.4983 185.72 13.0341L188.863 13.2559C188.567 16.4285 186.06 18.6471 182.696 18.6471C179.013 18.6471 176.721 15.8886 176.721 11.6955Z"
                              fill="white"
                            />
                            <path
                              d="M195.451 18.3513V1.28308H205.715V4.30773H198.476V8.21242H203.837V11.2371H198.476V15.334H206.063V18.3587H195.459L195.451 18.3513Z"
                              fill="white"
                            />
                            <path
                              d="M217 4.40384C217 6.44492 215.336 8.13103 213.287 8.13103C211.239 8.13103 209.523 6.44492 209.523 4.40384C209.516 2.33317 211.18 0.639663 213.287 0.647058C215.336 0.647058 217 2.34057 217 4.40384ZM216.142 4.40384C216.142 2.79168 214.87 1.5049 213.324 1.5049C211.683 1.5049 210.374 2.79168 210.374 4.40384C210.374 6.016 211.675 7.28058 213.324 7.28058C214.863 7.28058 216.142 5.97902 216.142 4.40384ZM214.663 3.6865C214.663 4.18198 214.478 4.53695 214.138 4.74402L214.833 6.33399H214.056L213.435 4.9289H212.836V6.33399H212.082V2.40712H213.428C214.197 2.40712 214.67 2.89521 214.67 3.64213V3.69389L214.663 3.6865ZM212.829 3.16144V4.18937H213.398C213.738 4.18937 213.923 4.00449 213.923 3.64952C213.923 3.33892 213.724 3.16144 213.398 3.16144H212.829Z"
                              fill="white"
                            />
                          </svg>
                        </a>
                      </span>
                      <button class="plus"></button>
                    </h4>
                    <ul>
                      <li>
                        <a
                          href="https://www.blocks-office.jp/fudomae/"
                          target="_blank"
                          rel="noopener"
                          data-link-type="external1"
                        >
                          BLOCKS 目黒不動前
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.blocks-office.jp/ebisu/"
                          target="_blank"
                          rel="noopener"
                          data-link-type="external1"
                        >
                          BLOCKS 恵比寿
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.blocks-office.jp/meguro/"
                          target="_blank"
                          rel="noopener"
                          data-link-type="external1"
                        >
                          BLOCKS 目黒
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.blocks-office.jp/ikebukuro/"
                          target="_blank"
                          rel="noopener"
                          data-link-type="external1"
                        >
                          BLOCKS 池袋
                        </a>
                      </li>
                      <li>
                        <span>BLOCKS 横浜山下町（2025年12月オープン予定）</span>
                      </li>
                    </ul>
                  </div>
                  <div class="profitzCommonFooter__sectionGridItemContent">
                    <h4>
                      <a href="https://effice.jp/" data-link-type="internal">
                        <svg
                          width="138"
                          height="20"
                          viewBox="0 0 138 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M134.86 14.4314C135.258 14.4314 135.461 14.5345 135.596 14.649C135.816 14.8351 135.882 15.0813 135.882 15.2988C135.882 15.5794 135.77 15.8285 135.544 15.9802C135.47 16.0317 135.367 16.0804 135.229 16.1061L136.131 17.3228H135.43L134.66 16.1605H134.591V17.3228H134.027V14.4343H134.863L134.86 14.4314ZM134.585 15.7626H134.745C134.854 15.7626 135.301 15.7483 135.301 15.3246C135.301 14.9009 134.86 14.8923 134.754 14.8923H134.585V15.7626Z"
                            fill="white"
                          />
                          <path
                            d="M134.897 18.7943C133.3 18.7943 132 17.4946 132 15.8972C132 14.2997 133.3 13 134.897 13C136.495 13 137.794 14.2997 137.794 15.8972C137.794 17.4946 136.495 18.7943 134.897 18.7943ZM134.897 13.4838C133.566 13.4838 132.481 14.566 132.481 15.9C132.481 17.2341 133.563 18.3162 134.897 18.3162C136.231 18.3162 137.313 17.2341 137.313 15.9C137.313 14.566 136.231 13.4838 134.897 13.4838Z"
                            fill="white"
                          />
                          <path
                            d="M13.9133 4.04944H3.24737V8.51755H13.6625V11.567H3.24737V16.0172H13.9133V19.0666H0V1H13.9133V4.04944Z"
                            fill="white"
                          />
                          <path
                            d="M38.5611 4.04944H29.024V9.21538H38.2963V12.2648H29.024V19.0666H25.7766V1H38.5611V4.04944Z"
                            fill="white"
                          />
                          <path
                            d="M63.2085 4.04944H53.6715V9.21538H62.9437V12.2648H53.6715V19.0666H50.4241V1H63.2085V4.04944Z"
                            fill="white"
                          />
                          <path
                            d="M78.3192 1V19.067H75.0718V1H78.3192Z"
                            fill="white"
                          />
                          <path
                            d="M103.539 5.02939V2.2691C102.098 1.42303 100.604 0.999996 99.0578 0.999996C96.226 0.999996 93.9183 2.05946 92.1345 4.17838C90.6852 5.90796 89.9605 7.87713 89.9605 10.0859C89.9605 12.5492 90.8264 14.6532 92.5581 16.3977C94.2973 18.1273 96.397 18.9921 98.8571 18.9921C100.492 18.9921 102.053 18.5691 103.539 17.723V14.9066C101.834 15.9563 100.437 16.5566 98.8905 16.5437C97.3441 16.5309 95.6277 15.9185 94.4088 14.6682C93.1899 13.4178 92.5804 11.8829 92.5804 10.0634C92.5804 8.22155 93.1899 6.66418 94.4088 5.39133C95.6203 4.11099 97.1105 3.47082 98.8794 3.47082C100.485 3.47082 101.757 3.88552 103.539 5.02939Z"
                            fill="white"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M103.879 2.0747V5.65065L103.356 5.31509C101.614 4.19665 100.404 3.8103 98.8794 3.8103C97.2034 3.8103 95.8025 4.41235 94.6554 5.62465L94.654 5.62613C93.4985 6.8327 92.9199 8.30467 92.9199 10.0634C92.9199 11.7985 93.4976 13.2471 94.6519 14.4312C95.8004 15.6094 97.4266 16.1921 98.8933 16.2043C100.343 16.2163 101.673 15.6571 103.362 14.6175L103.879 14.299V17.9204L103.707 18.018C102.172 18.8918 100.554 19.3316 98.8571 19.3316C96.3076 19.3316 94.1218 18.4315 92.3188 16.6384L92.3172 16.6369C90.5222 14.8286 89.6211 12.6386 89.6211 10.0859C89.6211 7.79553 90.375 5.74959 91.8743 3.96034L91.8748 3.95975C93.7247 1.76226 96.1284 0.660522 99.0578 0.660522C100.669 0.660522 102.222 1.10225 103.711 1.97631L103.879 2.0747ZM103.539 17.723C102.053 18.5691 100.492 18.9921 98.8571 18.9921C96.397 18.9921 94.2973 18.1273 92.5581 16.3977C90.8264 14.6532 89.9605 12.5492 89.9605 10.0859C89.9605 7.87713 90.6852 5.90796 92.1345 4.17838C93.9183 2.05946 96.226 0.999996 99.0578 0.999996C100.604 0.999996 102.098 1.42303 103.539 2.2691V5.02939C103.424 4.95545 103.311 4.88455 103.2 4.81661C101.591 3.83364 100.381 3.47082 98.8794 3.47082C97.1105 3.47082 95.6203 4.11099 94.4088 5.39133C93.1899 6.66418 92.5804 8.22155 92.5804 10.0634C92.5804 11.8829 93.1899 13.4178 94.4088 14.6682C95.6277 15.9185 97.3441 16.5309 98.8905 16.5437C100.333 16.5557 101.646 16.0341 103.2 15.1119C103.312 15.0455 103.425 14.977 103.539 14.9066V17.723Z"
                            fill="white"
                          />
                          <path
                            d="M129 4.04944H118.334V8.51755H128.749V11.567H118.334V16.0172H129V19.0666H115.087V1H129V4.04944Z"
                            fill="white"
                          />
                        </svg>
                      </a>
                      <button class="plus"></button>
                    </h4>
                    <ul>
                      <li>
                        <a
                          href="https://effice.jp/akasaka/"
                          data-link-type="internal"
                        >
                          EFFICE 赤坂
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://effice.jp/jimbocho/"
                          data-link-type="internal"
                        >
                          EFFICE 神保町
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="profitzCommonFooter__sectionGridItem">
                  <h3 class="profitzCommonFooter__sectionGridItemTitle">
                    Residence
                  </h3>
                  <div class="profitzCommonFooter__sectionGridItemContent">
                    <h4>
                      <a
                        href="https://wfluffy.com/"
                        target="_blank"
                        rel="noopener"
                        data-link-type="external1"
                      >
                        <svg
                          width="127"
                          height="21"
                          viewBox="0 0 127 21"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_47_1145)">
                            <path
                              d="M40.2934 5.21189H34.1527V8.47263H39.7352V12.7926H34.1527V20.4907H29.0684V0.891907H40.2934V5.21189Z"
                              fill="white"
                            />
                            <path
                              d="M48.4047 0.891907V16.1707H54.5168V20.4907H43.3203V0.891907H48.4047Z"
                              fill="white"
                            />
                            <path
                              d="M62.2143 0.891907V12.4977C62.2143 13.3194 62.2429 14.5847 63.2134 15.5237C63.8003 16.082 64.7422 16.4627 65.6239 16.4627C66.5057 16.4627 67.416 16.1106 67.9743 15.6096C69.1509 14.5819 69.1194 13.1419 69.1194 12.054V0.891907H74.2038V12.1456C74.2038 14.2011 74.115 16.3482 71.9994 18.4925C70.3533 20.1672 68.2978 20.9316 65.5352 20.9316C62.449 20.9316 60.2475 19.8723 59.0423 18.5812C57.3389 16.729 57.1328 14.2326 57.1328 12.1456V0.891907H62.2172H62.2143Z"
                              fill="white"
                            />
                            <path
                              d="M89.5415 5.21189H83.4008V8.47263H88.9832V12.7926H83.4008V20.4907H78.3164V0.891907H89.5415V5.21189Z"
                              fill="white"
                            />
                            <path
                              d="M103.793 5.21189H97.6527V8.47263H103.235V12.7926H97.6527V20.4907H92.5684V0.891907H103.793V5.21189Z"
                              fill="white"
                            />
                            <path
                              d="M111.491 11.0892L104.408 0.891907H110.492L114.193 6.23964L117.749 0.891907H123.918L116.601 11.0892V20.4907H111.488V11.0892H111.491Z"
                              fill="white"
                            />
                            <path
                              d="M3.66726 11.3326L5.84013 16.1679L8.15615 11.3326H10.163L12.479 16.311L14.775 11.3326H18.4222L13.627 20.4907H11.414L9.11805 15.4493L6.92514 20.4907H4.71219L0 11.3326H3.66726Z"
                              fill="white"
                            />
                            <path
                              d="M18.4169 20.4907L23.9107 0.891907H25.8058L20.3236 20.4907H18.4141H18.4169Z"
                              fill="white"
                            />
                            <path
                              d="M124.069 16.3683C124.467 16.3683 124.671 16.4713 124.805 16.5858C125.026 16.7719 125.091 17.0181 125.091 17.2357C125.091 17.5162 124.98 17.7653 124.754 17.917C124.679 17.9686 124.576 18.0172 124.439 18.043L125.34 19.2597H124.639L123.869 18.0974H123.8V19.2597H123.236V16.3711H124.072L124.069 16.3683ZM123.795 17.6995H123.955C124.064 17.6995 124.51 17.6851 124.51 17.2615C124.51 16.8378 124.069 16.8292 123.963 16.8292H123.795V17.6995Z"
                              fill="white"
                            />
                            <path
                              d="M124.106 20.7312C122.509 20.7312 121.209 19.4315 121.209 17.834C121.209 16.2366 122.509 14.9369 124.106 14.9369C125.704 14.9369 127.003 16.2366 127.003 17.834C127.003 19.4315 125.704 20.7312 124.106 20.7312ZM124.106 15.4207C122.775 15.4207 121.69 16.5028 121.69 17.8369C121.69 19.171 122.772 20.2531 124.106 20.2531C125.44 20.2531 126.522 19.171 126.522 17.8369C126.522 16.5028 125.44 15.4207 124.106 15.4207Z"
                              fill="white"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_47_1145">
                              <rect
                                width="127"
                                height="20.0397"
                                fill="white"
                                transform="translate(0 0.891907)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                      </a>
                      <button class="plus"></button>
                    </h4>
                    <ul>
                      <li>
                        <a
                          href="https://wfluffy.com/properties/nihonbashi-hamacho/"
                          target="_blank"
                          rel="noopener"
                          data-link-type="external1"
                        >
                          FLUFFY 日本橋浜町
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://wfluffy.com/properties/shirokanetakanawa/"
                          target="_blank"
                          rel="noopener"
                          data-link-type="external1"
                        >
                          FLUFFY 白金高輪
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://wfluffy.com/properties/ochiaiminaminagasaki/"
                          target="_blank"
                          rel="noopener"
                          data-link-type="external1"
                        >
                          FLUFFY 落合南長崎
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://wfluffy.com/properties/nishiwaseda/"
                          target="_blank"
                          rel="noopener"
                          data-link-type="external1"
                        >
                          FLUFFY 西早稲田
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://wfluffy.com/properties/denenchofu/"
                          target="_blank"
                          rel="noopener"
                          data-link-type="external1"
                        >
                          FLUFFY 田園調布
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://wfluffy.com/properties/jiyugaoka/"
                          target="_blank"
                          rel="noopener"
                          data-link-type="external1"
                        >
                          FLUFFY 自由が丘
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://wfluffy.com/properties/yutenji/"
                          target="_blank"
                          rel="noopener"
                          data-link-type="external1"
                        >
                          FLUFFY 祐天寺
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://wfluffy.com/properties/nakanosakaue/"
                          target="_blank"
                          rel="noopener"
                          data-link-type="external1"
                        >
                          FLUFFY 中野坂上
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://wfluffy.com/properties/ichigayadaimachi/"
                          target="_blank"
                          rel="noopener"
                          data-link-type="external1"
                        >
                          FLUFFY 市谷台町
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div class="profitzCommonFooter__sectionGridItemContent">
                    <h4>
                      <a
                        href="https://wfluffy.com/fluffy-s/"
                        target="_blank"
                        rel="noopener"
                        data-link-type="external1"
                      >
                        <svg
                          width="113"
                          height="21"
                          viewBox="0 0 113 21"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10.7228 5.8942H4.85614V9.00999H10.1895V13.1363H4.85614V20.4907H0V1.76788H10.7228V5.8942Z"
                            fill="white"
                          />
                          <path
                            d="M18.4733 1.76788V16.3644H24.3119V20.4907H13.6172V1.76788H18.4733Z"
                            fill="white"
                          />
                          <path
                            d="M31.6667 1.76788V12.8556C31.6667 13.6416 31.6948 14.8486 32.6211 15.7468C33.1825 16.2802 34.0807 16.6451 34.9228 16.6451C35.7649 16.6451 36.6351 16.3082 37.1684 15.831C38.2912 14.8486 38.2632 13.4731 38.2632 12.4346V1.76788H43.1193V12.5188C43.1193 14.4837 43.0351 16.5328 41.0141 18.5819C39.4421 20.1819 37.4772 20.9117 34.8386 20.9117C31.8912 20.9117 29.786 19.9012 28.6351 18.6661C27.007 16.8977 26.8105 14.5117 26.8105 12.5188V1.76788H31.6667Z"
                            fill="white"
                          />
                          <path
                            d="M57.7736 5.8942H51.9069V9.00999H57.2403V13.1363H51.9069V20.4907H47.0508V1.76788H57.7736V5.8942Z"
                            fill="white"
                          />
                          <path
                            d="M71.3908 5.8942H65.5241V9.00999H70.8574V13.1363H65.5241V20.4907H60.668V1.76788H71.3908V5.8942Z"
                            fill="white"
                          />
                          <path
                            d="M78.7454 11.5082L71.9805 1.76788H77.791L81.3278 6.87666L84.7243 1.76788H90.6191L83.6296 11.5082V20.4907H78.7454V11.5082Z"
                            fill="white"
                          />
                          <path
                            d="M88.5207 16.5552C88.9025 16.5552 89.0961 16.6535 89.2253 16.763C89.4358 16.9398 89.4975 17.1756 89.4975 17.3833C89.4975 17.6528 89.3909 17.8886 89.1747 18.0345C89.1046 18.0851 89.0063 18.13 88.8716 18.1552L89.7333 19.3173H89.0625L88.327 18.2086H88.2596V19.3173H87.7207V16.5552H88.5207ZM88.2596 17.824H88.414C88.5179 17.824 88.9446 17.8128 88.9446 17.4058C88.9446 16.9987 88.5235 16.9931 88.4225 16.9931H88.2596V17.824Z"
                            fill="white"
                          />
                          <path
                            d="M88.5548 20.7209C87.0278 20.7209 85.7871 19.4802 85.7871 17.9531C85.7871 16.4261 87.0278 15.1854 88.5548 15.1854C90.0818 15.1854 91.3225 16.4261 91.3225 17.9531C91.3225 19.4802 90.0818 20.7209 88.5548 20.7209ZM88.5548 15.6458C87.2833 15.6458 86.2475 16.6816 86.2475 17.9531C86.2475 19.2247 87.2833 20.2605 88.5548 20.2605C89.8264 20.2605 90.8622 19.2247 90.8622 17.9531C90.8622 16.6816 89.8264 15.6458 88.5548 15.6458Z"
                            fill="white"
                          />
                          <path
                            d="M91.7324 10.5763H97.3717V14.2816H91.7324V10.5763Z"
                            fill="white"
                          />
                          <path
                            d="M100.774 14.3377C101.804 15.3117 103.365 16.2324 105.038 16.2324C106.012 16.2324 106.599 15.9265 106.905 15.5924C107.183 15.3146 107.379 14.8963 107.379 14.4219C107.379 14.0598 107.239 13.6416 106.848 13.3075C106.374 12.8893 105.65 12.6956 104.451 12.3335L103.197 11.9433C102.416 11.6935 101.274 11.2753 100.521 10.4388C99.5727 9.40858 99.4632 8.09771 99.4632 7.15174C99.4632 4.86683 100.356 3.47455 101.164 2.66613C102.113 1.71736 103.671 0.911743 105.931 0.911743C107.769 0.911743 109.917 1.4956 111.671 2.4163L109.748 6.17771C108.3 5.03525 107.213 4.92297 106.571 4.92297C106.096 4.92297 105.456 5.03525 104.982 5.48157C104.704 5.75946 104.507 6.06543 104.507 6.48367C104.507 6.81771 104.564 7.06753 104.757 7.32016C104.898 7.48859 105.232 7.82262 106.374 8.15666L107.851 8.60297C109.021 8.96508 110.248 9.38332 111.222 10.4416C112.171 11.4437 112.42 12.4767 112.42 13.7567C112.42 15.4577 112.002 17.0998 110.666 18.4388C109.077 20.0275 107.154 20.3335 105.175 20.3335C104.005 20.3335 102.891 20.2212 101.526 19.7216C101.22 19.6093 100.05 19.163 98.7109 18.2451L100.774 14.3433V14.3377Z"
                            fill="white"
                          />
                        </svg>
                      </a>
                    </h4>
                  </div>
                </div>
                <div class="profitzCommonFooter__sectionGridItem">
                  <h3 class="profitzCommonFooter__sectionGridItemTitle">
                    Hotel
                  </h3>
                  <div class="profitzCommonFooter__sectionGridItemContent">
                    <h4>
                      <span>
                        <svg
                          width="223"
                          height="18"
                          viewBox="0 0 223 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_50_1844)">
                            <path
                              d="M0 6.04401H0.0895331C0.313366 5.72089 0.653591 5.47854 1.11916 5.308C1.58474 5.13747 2.22042 5.06566 3.02622 5.06566H4.50351V14.0055C4.50351 14.7325 4.45875 15.3069 4.36026 15.7378C4.27073 16.1596 4.09166 16.5366 3.82306 16.8418V16.9136H6.87614V16.8418C6.62545 16.5187 6.45533 16.1417 6.34789 15.7109C6.24941 15.289 6.19569 14.7146 6.19569 14.0055V5.06566H7.69089C8.50564 5.06566 9.14133 5.14644 9.58899 5.308C10.0456 5.46957 10.3858 5.71191 10.6097 6.04401H10.6992V3.86292H0V6.04401Z"
                              fill="white"
                            />
                            <path
                              d="M20.2433 10.2087C20.9327 9.91255 21.452 9.50864 21.8191 8.99702C22.1772 8.48541 22.3653 7.90199 22.3653 7.24676C22.3653 6.59153 22.1862 5.98118 21.8281 5.46957C21.4699 4.95795 20.9596 4.572 20.2791 4.28477C19.6076 4.00653 18.8018 3.86292 17.8617 3.86292H13.3672V3.93472C13.6089 4.23092 13.7611 4.59892 13.8417 5.02976C13.9223 5.46059 13.9671 6.03504 13.9671 6.75309V13.9875C13.9671 14.7235 13.9223 15.3069 13.8328 15.7288C13.7432 16.1507 13.591 16.5187 13.3672 16.8508V16.9226H16.2502V16.8508C16.0263 16.5276 15.8652 16.1507 15.7846 15.7288C15.695 15.3069 15.6503 14.7325 15.6503 13.9875V10.7024H17.0559L22.0788 16.9226H24.3081V16.8867L19.0257 10.5498C19.4644 10.478 19.8762 10.3613 20.2433 10.2087ZM15.6503 9.49966V5.06566H17.5394C18.5243 5.06566 19.2764 5.25415 19.7778 5.62215C20.2791 5.99016 20.5388 6.53768 20.5388 7.26471C20.5388 7.99174 20.2791 8.51233 19.7688 8.90727C19.2585 9.3022 18.5064 9.49966 17.5215 9.49966H15.6503Z"
                              fill="white"
                            />
                            <path
                              d="M37.237 14.3825C37.2102 14.3106 37.1833 14.2299 37.1565 14.1581C37.1206 14.0863 37.0938 14.0055 37.049 13.9157L33.0021 3.86292H31.4801L27.5227 14.0234C27.4958 14.0863 27.469 14.1491 27.4511 14.2119C27.4242 14.2747 27.3974 14.3376 27.3794 14.4004C27.1198 15.0646 26.896 15.5762 26.7259 15.9263C26.5468 16.2763 26.3319 16.5815 26.0723 16.8508V16.9226H28.6419V16.8508C28.4538 16.5546 28.3822 16.1686 28.4359 15.7109C28.4897 15.2531 28.6329 14.6966 28.8836 14.0503L29.3134 12.9463H34.8107L35.2315 14.0144C35.4822 14.6517 35.6433 15.2082 35.706 15.6839C35.7776 16.1596 35.7239 16.5456 35.5449 16.8508V16.9226H38.5084V16.8508C38.2756 16.5905 38.0607 16.2763 37.8817 15.9083C37.7026 15.5493 37.4877 15.0377 37.237 14.3914V14.3825ZM29.7879 11.7167L31.9994 6.00811H32.071L34.3272 11.7167H29.7789H29.7879Z"
                              fill="white"
                            />
                            <path
                              d="M49.9955 3.94375C50.2282 4.23995 50.3894 4.60796 50.47 5.02982C50.5506 5.45167 50.5953 6.03509 50.5953 6.76213V14.4633H50.4968L43.7371 3.87195H41.2033V3.90785L41.6331 4.59898V14.0145C41.6331 14.7415 41.5973 15.316 41.5077 15.7468C41.4182 16.1776 41.266 16.5457 41.0332 16.8508V16.9226H43.5849V16.8508C43.3611 16.5277 43.1999 16.1507 43.1193 15.7199C43.0298 15.298 42.985 14.7236 42.985 14.0145V5.93636H43.0835L50.1298 16.9226H51.9473V6.76213C51.9473 6.04407 51.992 5.4786 52.0816 5.05674C52.1711 4.63488 52.3233 4.2579 52.5472 3.95273V3.88092H49.9955V3.95273V3.94375Z"
                              fill="white"
                            />
                            <path
                              d="M56.9699 14.6697C56.6297 14.6697 56.3432 14.7953 56.1015 15.0377C55.8597 15.28 55.7344 15.5762 55.7344 15.9083C55.7344 16.2404 55.8597 16.5366 56.1015 16.779C56.3432 17.0213 56.6387 17.147 56.9699 17.147C57.3012 17.147 57.5967 17.0213 57.8384 16.779C58.0801 16.5366 58.2055 16.2404 58.2055 15.9083C58.2055 15.5762 58.0801 15.28 57.8384 15.0377C57.5967 14.7953 57.3012 14.6697 56.9699 14.6697Z"
                              fill="white"
                            />
                            <path
                              d="M66.9793 9.9754L65.1259 9.13169C64.5261 8.85344 64.0963 8.54826 63.8456 8.20719C63.586 7.86611 63.4606 7.48015 63.4606 7.04034C63.4606 6.37614 63.7113 5.84658 64.2127 5.46062C64.7141 5.07466 65.3498 4.8772 66.1197 4.8772C66.7554 4.8772 67.3464 5.02081 67.8925 5.30803C68.4387 5.59526 68.8863 6.02609 69.2534 6.60053H69.3429V4.53612C68.9579 4.27583 68.4745 4.06041 67.9104 3.88987C67.3464 3.71933 66.7465 3.63855 66.1197 3.63855C65.296 3.63855 64.5529 3.80909 63.8904 4.13221C63.2278 4.46432 62.7085 4.9131 62.3325 5.48755C61.9565 6.06199 61.7684 6.68132 61.7684 7.35449C61.7684 8.08153 61.9833 8.73675 62.4131 9.32018C62.8428 9.9036 63.4964 10.3973 64.3739 10.7922L66.2093 11.618C66.845 11.9052 67.3016 12.2014 67.5791 12.5066C67.8567 12.8117 67.991 13.1977 67.991 13.6644C67.991 14.1312 67.8656 14.5081 67.6239 14.8492C67.3822 15.1903 67.0509 15.4416 66.639 15.6211C66.2272 15.8006 65.7706 15.8814 65.2692 15.8814C64.544 15.8814 63.8635 15.7019 63.2368 15.3339C62.6101 14.9749 62.037 14.4363 61.5446 13.7183H61.4551V15.8814C61.9833 16.2853 62.5742 16.5905 63.2457 16.8149C63.9172 17.0303 64.5977 17.147 65.296 17.147C66.1198 17.147 66.8629 16.9854 67.5344 16.6713C68.2059 16.3482 68.7341 15.9083 69.1191 15.3249C69.5041 14.7415 69.7011 14.0953 69.7011 13.3682C69.7011 12.5784 69.4772 11.9231 69.0385 11.3936C68.5909 10.864 67.9104 10.3883 66.9882 9.96643L66.9793 9.9754Z"
                              fill="white"
                            />
                            <path
                              d="M81.2868 15.3069C80.6422 15.5313 79.9796 15.648 79.2902 15.648C78.3143 15.648 77.4369 15.4415 76.649 15.0287C75.8611 14.6158 75.2433 14.0054 74.7867 13.1976C74.3301 12.3898 74.1063 11.4204 74.1063 10.2805C74.1063 9.20343 74.3301 8.26098 74.7688 7.44419C75.2165 6.6274 75.8164 6.00807 76.5774 5.56826C77.3384 5.12845 78.18 4.91304 79.0843 4.91304C79.8095 4.91304 80.49 5.0746 81.1257 5.38875C81.7613 5.7029 82.2896 6.16964 82.7014 6.77101H82.791V4.60786C82.2448 4.29371 81.6629 4.06034 81.0451 3.88981C80.4363 3.72824 79.7558 3.64746 79.0037 3.64746C77.8129 3.64746 76.7027 3.91673 75.6642 4.46425C74.6256 5.01177 73.7929 5.79266 73.1572 6.82486C72.5215 7.84809 72.1992 9.05084 72.1992 10.4331C72.1992 11.8154 72.5036 13.045 73.1214 14.0593C73.7392 15.0735 74.545 15.8455 75.5657 16.366C76.5863 16.8956 77.6965 17.1559 78.8873 17.1559C79.8006 17.1559 80.6243 16.9943 81.3495 16.6802C82.0837 16.3571 82.7641 15.8903 83.3998 15.262L83.1939 14.2837C82.5582 14.7504 81.9136 15.0915 81.2689 15.3159L81.2868 15.3069Z"
                              fill="white"
                            />
                            <path
                              d="M94.0627 15.4506C93.615 15.6212 93.0062 15.7019 92.2452 15.7019H88.2162V10.9089H91.1171C91.8512 10.9089 92.4153 10.9628 92.8003 11.0705C93.1853 11.1782 93.4897 11.3577 93.7225 11.609H93.812V9.01503H93.7225C93.4987 9.25738 93.1853 9.42791 92.7913 9.53562C92.3974 9.64333 91.8423 9.69719 91.1171 9.69719H88.2162V5.09265H91.9855C92.8093 5.09265 93.436 5.15548 93.8568 5.29011C94.2776 5.42475 94.6089 5.65811 94.8506 5.99919H94.9401V3.87195H85.9062V3.94375C86.139 4.23995 86.3002 4.60796 86.3808 5.02982C86.4614 5.45167 86.5061 6.03509 86.5061 6.78008V14.1222C86.5061 14.7954 86.4614 15.3429 86.3718 15.7558C86.2823 16.1687 86.1301 16.5367 85.9062 16.8419V16.9137H95.1819V14.5441H95.0923C94.8596 14.9659 94.5104 15.2711 94.0627 15.4327V15.4506Z"
                              fill="white"
                            />
                            <path
                              d="M107.054 3.94375C107.287 4.23995 107.448 4.60796 107.529 5.02982C107.609 5.45167 107.654 6.03509 107.654 6.76213V14.4633H107.555L100.796 3.87195H98.2619V3.90785L98.6917 4.59898V14.0145C98.6917 14.7415 98.6559 15.316 98.5663 15.7468C98.4768 16.1776 98.3246 16.5457 98.0918 16.8508V16.9226H100.643V16.8508C100.42 16.5277 100.258 16.1507 100.178 15.7199C100.088 15.298 100.044 14.7236 100.044 14.0145V5.93636H100.142L107.188 16.9226H109.006V6.76213C109.006 6.04407 109.051 5.4786 109.14 5.05674C109.23 4.63488 109.382 4.2579 109.606 3.95273V3.88092H107.054V3.95273V3.94375Z"
                              fill="white"
                            />
                            <path
                              d="M121.29 4.67971C120.234 4.13219 119.016 3.86292 117.646 3.86292H112.928V3.93472C113.152 4.2758 113.313 4.65278 113.393 5.07464C113.483 5.49649 113.528 6.07094 113.528 6.79797V13.9157C113.528 14.7056 113.492 15.3159 113.42 15.7468C113.349 16.1776 113.178 16.5456 112.937 16.8418V16.9136H117.655C119.034 16.9136 120.243 16.6443 121.299 16.0968C122.356 15.5493 123.17 14.7864 123.752 13.799C124.334 12.8117 124.621 11.6718 124.621 10.3793C124.621 9.08678 124.325 7.96482 123.752 6.97749C123.179 5.99016 122.356 5.21825 121.299 4.67971H121.29ZM122.051 13.3143C121.603 14.1132 120.968 14.7146 120.144 15.1185C119.32 15.5224 118.353 15.7198 117.243 15.7198H115.238V5.06566H117.243C118.353 5.06566 119.32 5.26313 120.144 5.66703C120.968 6.07094 121.603 6.66334 122.051 7.46218C122.499 8.26101 122.723 9.23039 122.723 10.3793C122.723 11.5282 122.499 12.5065 122.051 13.3054V13.3143Z"
                              fill="white"
                            />
                            <path
                              d="M135.625 15.4506C135.178 15.6212 134.569 15.7019 133.808 15.7019H129.779V10.9089H132.68C133.414 10.9089 133.978 10.9628 134.363 11.0705C134.748 11.1782 135.052 11.3577 135.285 11.609H135.375V9.01503H135.285C135.061 9.25738 134.748 9.42791 134.354 9.53562C133.96 9.64333 133.405 9.69719 132.68 9.69719H129.779V5.09265H133.548C134.372 5.09265 134.998 5.15548 135.419 5.29011C135.84 5.42475 136.171 5.65811 136.413 5.99919H136.503V3.87195H127.469V3.94375C127.702 4.23995 127.863 4.60796 127.943 5.02982C128.024 5.45167 128.069 6.03509 128.069 6.78008V14.1222C128.069 14.7954 128.024 15.3429 127.934 15.7558C127.845 16.1687 127.693 16.5367 127.469 16.8419V16.9137H136.744V14.5441H136.655C136.422 14.9659 136.073 15.2711 135.625 15.4327V15.4506Z"
                              fill="white"
                            />
                            <path
                              d="M146.538 10.2087C147.228 9.91255 147.747 9.50864 148.114 8.99702C148.472 8.48541 148.66 7.90199 148.66 7.24676C148.66 6.59153 148.481 5.98118 148.123 5.46957C147.765 4.95795 147.255 4.572 146.574 4.28477C145.903 4.00653 145.097 3.86292 144.157 3.86292H139.662V3.93472C139.904 4.23092 140.056 4.59892 140.137 5.02976C140.217 5.46059 140.262 6.03504 140.262 6.75309V13.9875C140.262 14.7235 140.217 15.3069 140.128 15.7288C140.038 16.1507 139.886 16.5187 139.662 16.8508V16.9226H142.545V16.8508C142.321 16.5276 142.16 16.1507 142.079 15.7288C141.99 15.3069 141.945 14.7325 141.945 13.9875V10.7024H143.351L148.374 16.9226H150.603V16.8867L145.321 10.5498C145.759 10.478 146.171 10.3613 146.538 10.2087ZM141.945 9.49966V5.06566H143.834C144.819 5.06566 145.571 5.25415 146.073 5.62215C146.574 5.99016 146.834 6.53768 146.834 7.26471C146.834 7.99174 146.574 8.51233 146.064 8.90727C145.553 9.3022 144.801 9.49966 143.816 9.49966H141.945Z"
                              fill="white"
                            />
                            <path
                              d="M154.032 3.59369C153.674 3.38725 153.262 3.27954 152.805 3.27954C152.349 3.27954 151.937 3.38725 151.579 3.59369C151.221 3.80013 150.934 4.08736 150.728 4.45536C150.522 4.82336 150.424 5.24522 150.424 5.71196C150.424 6.1787 150.522 6.59158 150.728 6.95958C150.934 7.32759 151.221 7.61481 151.579 7.83023C151.937 8.04565 152.349 8.14438 152.805 8.14438C153.262 8.14438 153.674 8.03667 154.032 7.83023C154.39 7.62379 154.677 7.33656 154.883 6.95958C155.089 6.59158 155.187 6.16972 155.187 5.71196C155.187 5.2542 155.089 4.82336 154.883 4.45536C154.677 4.08736 154.399 3.80013 154.032 3.59369ZM154.533 6.75314C154.363 7.06729 154.13 7.30964 153.826 7.48018C153.522 7.65969 153.181 7.74047 152.805 7.74047C152.429 7.74047 152.089 7.65071 151.785 7.48018C151.48 7.30066 151.248 7.05832 151.077 6.75314C150.907 6.43899 150.827 6.08894 150.827 5.69401C150.827 5.29908 150.907 4.94902 151.077 4.63487C151.248 4.32072 151.48 4.07838 151.785 3.90784C152.089 3.7373 152.429 3.64755 152.805 3.64755C153.181 3.64755 153.522 3.7373 153.826 3.90784C154.13 4.07838 154.363 4.32072 154.533 4.63487C154.704 4.94902 154.784 5.29908 154.784 5.69401C154.784 6.08894 154.704 6.43899 154.533 6.75314Z"
                              fill="white"
                            />
                            <path
                              d="M153.531 5.63108C153.684 5.50542 153.764 5.34386 153.764 5.13742C153.764 4.91303 153.675 4.74249 153.504 4.6258C153.325 4.50014 153.102 4.44629 152.824 4.44629H151.875V4.47322C151.911 4.51809 151.938 4.58092 151.956 4.65273C151.973 4.72454 151.982 4.81429 151.982 4.93995V6.46582C151.982 6.59148 151.982 6.69022 151.956 6.76202C151.938 6.83383 151.911 6.89666 151.875 6.93256V6.95949H152.546V6.93256C152.546 6.93256 152.484 6.82485 152.466 6.75305C152.448 6.68124 152.439 6.58251 152.439 6.46582V5.85548H152.6L153.487 6.95949H154.078L153.128 5.8106C153.281 5.77469 153.424 5.72084 153.531 5.64006V5.63108ZM152.699 5.5503H152.439V4.76044H152.699C152.887 4.76044 153.021 4.78737 153.12 4.8502C153.218 4.91303 153.263 5.01176 153.263 5.14639C153.263 5.28103 153.209 5.38874 153.111 5.45157C153.012 5.5144 152.869 5.5503 152.69 5.5503H152.699Z"
                              fill="white"
                            />
                            <path
                              d="M168.223 3.94375C168.447 4.2579 168.599 4.62591 168.689 5.04777C168.778 5.46962 168.823 6.05305 168.823 6.78008V9.67026H161.553V6.78008C161.553 6.05305 161.598 5.4786 161.687 5.04777C161.777 4.61693 161.929 4.24893 162.153 3.94375V3.87195H159.252V3.94375C159.485 4.2579 159.646 4.62591 159.726 5.03879C159.807 5.46065 159.852 6.03509 159.852 6.78008V14.0145C159.852 14.7415 159.807 15.316 159.726 15.7468C159.646 16.1776 159.485 16.5457 159.252 16.8508V16.9226H162.153V16.8508C161.929 16.5277 161.768 16.1507 161.687 15.7199C161.598 15.298 161.553 14.7236 161.553 14.0145V10.9179H168.823V14.0145C168.823 14.7326 168.778 15.298 168.689 15.7199C168.599 16.1417 168.447 16.5187 168.223 16.8508V16.9226H171.124V16.8508C170.891 16.5367 170.73 16.1687 170.65 15.7468C170.569 15.325 170.524 14.7415 170.524 14.0145V6.78008C170.524 6.04407 170.56 5.46065 170.65 5.03879C170.73 4.61693 170.891 4.24893 171.124 3.94375V3.87195H168.223V3.94375Z"
                              fill="white"
                            />
                            <path
                              d="M180.489 1.29586L181.967 2.6871H183.319L181.241 0.146973H179.755L177.66 2.6871H178.949L180.489 1.29586Z"
                              fill="white"
                            />
                            <path
                              d="M183.811 4.52708C182.826 3.93468 181.716 3.64746 180.463 3.64746C179.209 3.64746 178.117 3.94366 177.141 4.52708C176.156 5.11948 175.395 5.92729 174.849 6.95052C174.303 7.97375 174.025 9.13162 174.025 10.4062C174.025 11.6807 174.303 12.8386 174.849 13.8618C175.395 14.8851 176.165 15.6929 177.141 16.2763C178.117 16.8597 179.227 17.1469 180.463 17.1469C181.698 17.1469 182.826 16.8597 183.811 16.2763C184.796 15.6929 185.557 14.894 186.094 13.8618C186.641 12.8386 186.909 11.6807 186.909 10.4062C186.909 9.13162 186.641 7.97375 186.094 6.95052C185.548 5.92729 184.787 5.11948 183.811 4.52708ZM184.456 13.2604C184.071 14.0862 183.534 14.7325 182.844 15.1902C182.155 15.648 181.358 15.8724 180.472 15.8724C179.585 15.8724 178.798 15.648 178.099 15.1902C177.41 14.7325 176.864 14.0952 176.488 13.2604C176.103 12.4347 175.915 11.4833 175.915 10.4062C175.915 9.32909 176.103 8.37766 176.488 7.54292C176.864 6.70818 177.41 6.06193 178.099 5.59519C178.789 5.12845 179.585 4.90406 180.472 4.90406C181.358 4.90406 182.146 5.13743 182.844 5.59519C183.543 6.05295 184.071 6.70818 184.456 7.54292C184.841 8.37766 185.029 9.32909 185.029 10.4062C185.029 11.4833 184.841 12.4347 184.456 13.2604Z"
                              fill="white"
                            />
                            <path
                              d="M188.735 6.04401H188.825C189.048 5.72089 189.389 5.47854 189.854 5.308C190.32 5.13747 190.955 5.06566 191.761 5.06566H193.239V14.0055C193.239 14.7325 193.194 15.3069 193.095 15.7378C193.006 16.1596 192.827 16.5366 192.558 16.8418V16.9136H195.611V16.8418C195.36 16.5187 195.19 16.1417 195.083 15.7109C194.984 15.289 194.931 14.7146 194.931 14.0055V5.06566H196.426C197.241 5.06566 197.876 5.14644 198.324 5.308C198.781 5.46957 199.121 5.71191 199.345 6.04401H199.434V3.86292H188.699V6.04401H188.735Z"
                              fill="white"
                            />
                            <path
                              d="M210.26 15.4506C209.812 15.6212 209.203 15.7019 208.442 15.7019H204.413V10.9089H207.314C208.049 10.9089 208.613 10.9628 208.998 11.0705C209.383 11.1782 209.687 11.3577 209.92 11.609H210.009V9.01503H209.92C209.696 9.25738 209.383 9.42791 208.989 9.53562C208.595 9.64333 208.04 9.69719 207.314 9.69719H204.413V5.09265H208.183C209.007 5.09265 209.633 5.15548 210.054 5.29011C210.475 5.42475 210.806 5.65811 211.048 5.99919H211.137V3.87195H202.104V3.94375C202.336 4.23995 202.497 4.60796 202.578 5.02982C202.659 5.45167 202.703 6.03509 202.703 6.78008V14.1222C202.703 14.7954 202.659 15.3429 202.569 15.7558C202.48 16.1687 202.327 16.5367 202.104 16.8419V16.9137H211.379V14.5441H211.29C211.057 14.9659 210.708 15.2711 210.26 15.4327V15.4506Z"
                              fill="white"
                            />
                            <path
                              d="M222.902 14.3466C222.678 14.8762 222.338 15.2262 221.89 15.4147C221.442 15.6032 220.807 15.693 220.001 15.693H216.608V6.76213C216.608 6.03509 216.643 5.46065 216.733 5.02982C216.814 4.60796 216.975 4.23995 217.208 3.94375V3.87195H214.307V3.94375C214.53 4.2579 214.692 4.62591 214.772 5.04777C214.853 5.46962 214.907 6.04407 214.907 6.75315V14.1132C214.907 14.7774 214.862 15.325 214.763 15.7468C214.674 16.1687 214.513 16.5367 214.307 16.8329V16.9047H223V14.3287H222.911L222.902 14.3466Z"
                              fill="white"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_50_1844">
                              <rect
                                width="223"
                                height="17"
                                fill="white"
                                transform="translate(0 0.146973)"
                              />
                            </clipPath>
                          </defs>
                        </svg>
                      </span>
                      <button class="plus"></button>
                    </h4>
                    <ul>
                      <li>
                        <span>Coming Soon</span>
                      </li>
                    </ul>
                  </div>
                  <h3 class="profitzCommonFooter__sectionGridItemTitle">IT</h3>
                  <div class="profitzCommonFooter__sectionGridItemContent">
                    <h4>
                      <a
                        href="https://lp.reach-property.com/"
                        target="_blank"
                        rel="noopener"
                        data-link-type="external1"
                      >
                        <svg
                          width="126"
                          height="31"
                          viewBox="0 0 126 31"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M5.00226 21.2688V7.75354C1.88713 11.3753 0 16.0963 0 21.2688H5.00226Z"
                            fill="white"
                          />
                          <path
                            d="M7.80078 21.2689H12.803V2.13403C10.9927 2.86841 9.3132 3.85823 7.80078 5.05786V21.2689Z"
                            fill="white"
                          />
                          <path
                            d="M20.411 0.646973C18.7541 0.646973 17.1514 0.852235 15.6074 1.22627V21.2688H20.6097V0.651535C20.542 0.651535 20.4788 0.646973 20.411 0.646973Z"
                            fill="white"
                          />
                          <path
                            d="M31.8779 19.9916C31.9231 20.5025 32.063 20.6621 32.5867 20.8035V21.173H27.8418V20.8035C28.37 20.6667 28.5055 20.5025 28.5506 19.9916V6.90966C28.5055 6.39879 28.3655 6.23914 27.8418 6.09774V5.72827H37.3723C39.7109 5.72827 40.9253 5.98371 41.9321 6.65423C43.0337 7.37036 43.6747 8.71595 43.6747 10.3124C43.6747 12.8349 42.2978 14.3173 39.5258 14.7552L42.4829 19.0155C43.5845 20.5663 43.8102 20.7534 44.5235 20.799V21.1684H39.0021V20.799C39.3678 20.7534 39.5754 20.5891 39.5754 20.4067C39.5754 20.247 39.4851 20.06 39.0246 19.4123L35.9547 14.8738H31.8779V19.9916ZM37.0337 12.3285C38.5461 12.3285 38.9795 12.2601 39.5077 11.9819C40.0359 11.6808 40.3565 11.0559 40.3565 10.2942C40.3565 9.53244 40.0359 8.93034 39.5077 8.62929C38.9795 8.32824 38.5912 8.28262 37.0337 8.28262H31.8779V12.3331H37.0337V12.3285Z"
                            fill="white"
                          />
                          <path
                            d="M52.5869 18.6278H62.4379C62.921 18.5822 63.1016 18.4408 63.2415 17.9117H63.5846V21.8937H63.2415C63.1016 21.36 62.9436 21.2232 62.4379 21.1776H48.5508V20.8081C49.079 20.6713 49.2144 20.5071 49.2596 19.9962V6.91425C49.2144 6.40338 49.0745 6.24373 48.5508 6.10233V5.73286H62.1625C62.6682 5.68725 62.8262 5.54584 62.9661 5.01672H63.3318V8.99879H62.9661C62.8307 8.46511 62.6682 8.32827 62.1625 8.28265H52.5869V11.9637H58.7946C59.3002 11.9409 59.4582 11.7766 59.5982 11.2247H59.9639V15.1384H59.5982C59.4627 14.6047 59.3002 14.4678 58.7946 14.4222H52.5869V18.6369V18.6278Z"
                            fill="white"
                          />
                          <path
                            d="M72.7907 17.2366L71.7614 19.4123C71.6485 19.6906 71.5537 19.9688 71.5537 20.1285C71.5537 20.4523 71.8065 20.6849 72.2851 20.799V21.1684H67.2422V20.799C67.8156 20.7077 67.9961 20.5207 68.4566 19.6176L74.0684 7.73982C74.2535 7.37035 74.3438 7.06931 74.3438 6.83668C74.3438 6.49001 74.1361 6.23458 73.7027 6.09774V5.72827H79.1113V6.09774C78.723 6.21177 78.4476 6.4444 78.4476 6.69983C78.4476 6.93246 78.583 7.37036 78.8359 7.85842L84.4973 19.4123C85.0481 20.4751 85.2783 20.7077 85.8742 20.8035V21.173H80.2625V20.8035C80.7456 20.7351 80.9939 20.5025 80.9939 20.1102C80.9939 19.9232 80.9262 19.7179 80.7862 19.4169L79.7343 17.2411H72.7907V17.2366ZM76.2986 9.6191L73.7794 15.1292H78.7727L76.2986 9.6191Z"
                            fill="white"
                          />
                          <path
                            d="M104.663 19.0885L104.388 19.2983C104.157 19.0885 104.022 19.02 103.837 19.02C103.674 19.02 103.241 19.2299 102.735 19.5537C100.789 20.8263 98.6585 21.4512 96.3651 21.4512C93.9587 21.4512 91.9678 20.872 90.4283 19.7134C88.4824 18.2765 87.3809 16.0096 87.3809 13.4415C87.3809 10.8735 88.4824 8.62473 90.4283 7.19246C91.9858 6.03388 93.9587 5.45459 96.3876 5.45459C98.6811 5.45459 100.491 5.98827 102.595 7.28369C103.349 7.74895 103.674 7.88579 103.9 7.88579C104.035 7.88579 104.13 7.81737 104.383 7.60755L104.659 7.81737L102.55 11.0377L102.252 10.8279V10.5268C102.252 10.2486 102.184 10.0889 101.932 9.8791C100.627 8.74332 98.6766 8.07281 96.6405 8.07281C93.2048 8.07281 90.7985 10.2714 90.7985 13.4643C90.7985 15.038 91.4621 16.5204 92.6314 17.5148C93.6833 18.4179 95.0152 18.833 96.803 18.833C98.338 18.833 99.5976 18.4864 100.975 17.6973C101.914 17.1636 102.257 16.8169 102.257 16.4018V16.078L102.555 15.8682L104.663 19.0839V19.0885Z"
                            fill="white"
                          />
                          <path
                            d="M112.858 14.4359V19.9916C112.881 20.5025 113.043 20.6621 113.567 20.8035V21.173H108.822V20.8035C109.35 20.6667 109.486 20.5025 109.531 19.9916V6.90966C109.486 6.39879 109.346 6.23914 108.822 6.09774V5.72827H113.567V6.09774C113.039 6.23914 112.904 6.39879 112.858 6.90966V11.8861H121.703V6.90966C121.657 6.39879 121.518 6.23914 120.994 6.09774V5.72827H125.739V6.09774C125.211 6.23914 125.053 6.39879 125.03 6.90966V19.9916C125.053 20.5025 125.215 20.6621 125.739 20.8035V21.173H120.994V20.8035C121.522 20.6667 121.657 20.5025 121.703 19.9916V14.4359H112.858Z"
                            fill="white"
                          />
                          <path
                            d="M84.7217 24.7355C85.0377 24.7355 85.6562 24.7355 86.1212 25.1004C86.4553 25.3604 86.654 25.812 86.654 26.2955C86.654 27.039 86.1664 27.3446 86.0129 27.4312C86.3921 27.4905 86.6133 27.6547 86.7578 27.7961C87.1009 28.1246 87.1686 28.5761 87.1686 28.8817C87.1686 29.2193 87.0738 29.6982 86.6811 30.0494C86.2522 30.4326 85.7285 30.5193 85.2318 30.5193H83.3086V24.74H84.7217V24.7355ZM84.4192 27.1347H84.7352C84.9068 27.1347 85.602 27.1165 85.602 26.3821C85.602 25.853 85.2002 25.6523 84.7443 25.6523H84.4192V27.1347ZM84.4192 29.5979H84.8978C85.1054 29.5979 85.4124 29.5887 85.6607 29.4428C85.9361 29.2786 86.0129 29.0003 86.0129 28.7768C86.0129 28.5944 85.9542 28.2842 85.593 28.12C85.3537 28.006 85.0693 28.006 84.83 28.006H84.4192V29.6024V29.5979Z"
                            fill="white"
                          />
                          <path
                            d="M89.4893 27.8737L87.5254 24.7355H88.8482L90.0762 26.747L91.3042 24.7355H92.627L90.6134 27.8737V30.5147H89.4983V27.8737H89.4893Z"
                            fill="white"
                          />
                          <path
                            d="M96.9159 24.7355C97.557 24.7355 98.0039 24.8039 98.4238 25.1779C98.8888 25.593 99.0062 26.1586 99.0062 26.5737C99.0062 26.7972 98.9701 27.4221 98.5367 27.8646C98.2207 28.1839 97.7466 28.3754 97.0875 28.3754H96.3651V30.5147H95.25V24.7355H96.9159ZM96.3651 27.4039H96.8211C97.0378 27.4039 97.3087 27.4039 97.5525 27.2214C97.7602 27.0572 97.8595 26.7972 97.8595 26.5463C97.8595 26.227 97.706 26.0081 97.5434 25.8895C97.3132 25.7253 97.0197 25.707 96.7534 25.707H96.3651V27.4039Z"
                            fill="white"
                          />
                          <path
                            d="M101.489 24.7355C102.279 24.7355 102.681 24.9453 102.947 25.1688C103.385 25.5428 103.511 26.0355 103.511 26.4688C103.511 27.0298 103.29 27.527 102.843 27.8281C102.699 27.933 102.491 28.0288 102.216 28.0789L103.999 30.5147H102.609L101.083 28.193H100.947V30.5147H99.832V24.7355H101.489ZM100.947 27.3947H101.263C101.475 27.3947 102.36 27.3674 102.36 26.519C102.36 25.6706 101.484 25.6523 101.281 25.6523H100.947V27.3947Z"
                            fill="white"
                          />
                          <path
                            d="M110.59 27.632C110.59 29.3926 109.34 30.647 107.538 30.647C105.737 30.647 104.486 29.3926 104.486 27.632C104.486 25.8713 105.737 24.6078 107.538 24.6078C109.34 24.6078 110.59 25.8713 110.59 27.632ZM109.439 27.632C109.439 26.4643 108.649 25.6295 107.534 25.6295C106.419 25.6295 105.629 26.4597 105.629 27.632C105.629 28.8042 106.419 29.6253 107.534 29.6253C108.649 29.6253 109.439 28.8042 109.439 27.632Z"
                            fill="white"
                          />
                          <path
                            d="M114.654 25.707H112.73V26.9979H114.532V27.9695H112.73V30.5147H111.615V24.7355H114.654V25.707Z"
                            fill="white"
                          />
                          <path
                            d="M116.73 24.7355V30.5147H115.615V24.7355H116.73Z"
                            fill="white"
                          />
                          <path
                            d="M119.837 25.707V30.5147H118.722V25.707H117.436V24.7355H121.124V25.707H119.837Z"
                            fill="white"
                          />
                          <path
                            d="M123.218 29.5478H125.859V30.5193H121.367L124.148 25.7117H121.805V24.7401H125.999L123.218 29.5478Z"
                            fill="white"
                          />
                        </svg>
                      </a>
                    </h4>
                  </div>
                </div>
              </div>
            </div>
            <div class="profitzCommonFooter__section">
              <h2 class="profitzCommonFooter__sectionTitle">
                List of Managed Assets
              </h2>
              <div class="profitzCommonFooter__sectionGrid-1col">
                <div class="profitzCommonFooter__sectionGridItem">
                  <div class="profitzCommonFooter__sectionGridItemContent">
                    <h4>
                      <span>List of Managed Assets</span>
                      <button class="plus"></button>
                    </h4>
                    <ul>
                      <li>
                        <a
                          href="https://section-l.co/hamamatsucho/?lang=ja"
                          target="_blank"
                          rel="noopener"
                          data-link-type="external1"
                        >
                          Section L Hamamatsucho
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://section-l.co/shimbashi-west/?lang=ja"
                          target="_blank"
                          rel="noopener"
                          data-link-type="external1"
                        >
                          Section L Shinbashi West
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.blocks-office.jp/meguro/residence/"
                          target="_blank"
                          rel="noopener"
                          data-link-type="external1"
                        >
                          MG目黒駅前
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.porsche.co.jp/dealers/adachi/"
                          target="_blank"
                          rel="noopener"
                          data-link-type="external1"
                        >
                          ポルシェセンター足立
                        </a>
                      </li>
                      <li>
                        <span>保育園みらいファンド</span>
                      </li>
                      <li>
                        <span>WOWK芝公園</span>
                      </li>
                      <li>
                        <a
                          href="https://piecehotel.com/41pieces/"
                          target="_blank"
                          rel="noopener"
                          data-link-type="external1"
                        >
                          41 PIECES Sapporo
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://piecehotel.com/20-22pieces/"
                          target="_blank"
                          rel="noopener"
                          data-link-type="external1"
                        >
                          22 PIECES
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://cabo-uehara.jp/"
                          target="_blank"
                          rel="noopener"
                          data-link-type="external1"
                        >
                          CABO uehara
                        </a>
                      </li>
                    </ul>
                  </div>
                  <h3 class="profitzCommonFooter__sectionGridItemTitle">
                    Other
                  </h3>
                  <div class="profitzCommonFooter__sectionGridItemContent">
                    <ul>
                      <li>
                        <a
                          href="https://ballparkproject.com/"
                          target="_blank"
                          rel="noopener"
                          data-link-type="external1"
                        >
                          BALL PARK PROJECT
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="visible-on-desktop">
            <div class="profitzCommonFooter__subNavigation">
              <ul>
                <li>
                  <a
                    href="https://profitz.jp/p/solicitation-policy"
                    target="_blank"
                    rel="noopener"
                    data-link-type="external0"
                  >
                    金融商品販売における勧誘方針
                  </a>
                </li>
                <li>
                  <a
                    href="https://profitz.jp/p/privacy-policy"
                    target="_blank"
                    rel="noopener"
                    data-link-type="external0"
                  >
                    個人情報保護宣言
                  </a>
                </li>
                <li>
                  <a
                    href="https://profitz.jp/p/adr-policy"
                    target="_blank"
                    rel="noopener"
                    data-link-type="external0"
                  >
                    苦情解決および金融ADR制度における紛争解決の措置方針
                  </a>
                </li>
                <li>
                  <a
                    href="https://profitz.jp/p/antisocial-policy"
                    target="_blank"
                    rel="noopener"
                    data-link-type="external0"
                  >
                    反社会的勢力による被害を防止するための基本方針
                  </a>
                </li>
                <li>
                  <a
                    href="https://profitz.jp/p/disclaimer"
                    target="_blank"
                    rel="noopener"
                    data-link-type="external0"
                  >
                    免責事項と金融商品取引法に基づく表示
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="profitzCommonFooter__copyright">
            <p>©PROFITZ All Rights Reserved.</p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
